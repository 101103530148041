<template>
    <el-table
      :data="items"
      style="width: 100%">
      <el-table-column
        prop="_id"
        label="ID"
        width="220">
      </el-table-column>
      <el-table-column
        prop="subject"
        label="标题">
      </el-table-column>
      <el-table-column
        prop="body"
        label="内容">
      </el-table-column>
      <el-table-column
        prop="out_trade_no"
        label="自定义订单号">
      </el-table-column>
      <el-table-column
        prop="total_amount"
        label="总金额">
      </el-table-column>
      <el-table-column
        prop="goods_detail"
        label="商品详情">
      </el-table-column>
      <el-table-column
        prop="trade_status"
        label="交易状态">
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="200">
        <template slot-scope="scope">
          <el-button type="primary" @click="$router.push(`/pay/edit/${scope.row._id}`)" size="small">编辑</el-button>
          <el-button type="danger" @click="remove(scope.row)" size="small">删除</el-button>
        </template>
        </el-table-column>
    </el-table>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      items: []
    }
  },
  computed: {},
  watch: {},
  created () {
    this.fetch()
  },
  mounted () {},
  methods: {
    async fetch () {
      const res = await this.$http.get('rest/pay')
      this.items = res.data
    },
    remove (row) {
      this.$confirm(`确定删除 "${row.out_trade_no}" ?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$http.delete(`rest/pay/${row._id}`)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.fetch()
      })
    }
  }
}
</script>

<style scoped lang="less">
</style>
