import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Main from '../views/Main.vue'

import CategoryEdit from '../views/CategoryEdit.vue'
import CategoryList from '../views/CategoryList.vue'
import ArticleEdit from '../views/ArticleEdit.vue'
import ArticleList from '../views/ArticleList.vue'
import AdminUserEdit from '../views/AdminUserEdit.vue'
import AdminUserList from '../views/AdminUserList.vue'
import DataokeEdit from '../views/dataokeEdit.vue'
import WxmpList from '../views/WxmpList.vue'
import WxmpEdit from '../views/WxmpEdit.vue'
import WxmpBoardcast from '../views/WxmpBoardcast.vue'
import PayList from '../views/PayList.vue'
import PayEdit from '../views/PayEdit.vue'
import ProductEdit from '../views/ProductEdit.vue'
import ProductList from '../views/ProductList.vue'
import OrderEdit from '../views/OrderEdit.vue'
import OrderList from '../views/OrderList.vue'
import UserEdit from '../views/UserEdit.vue'
import UserList from '../views/UserList.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { isPublic: true }
  },
  {
    path: '/',
    name: 'main',
    component: Main,
    children: [
      { path: '/category/create', component: CategoryEdit },
      { path: '/category/edit/:id', component: CategoryEdit, props: true },
      { path: '/category/list', component: CategoryList },

      { path: '/article/create', component: ArticleEdit },
      { path: '/article/edit/:id', component: ArticleEdit, props: true },
      { path: '/article/list', component: ArticleList },

      { path: '/admin_user/create', component: AdminUserEdit },
      { path: '/admin_user/edit/:id', component: AdminUserEdit, props: true },
      { path: '/admin_user/list', component: AdminUserList },

      { path: '/pay/create', component: PayEdit },
      { path: '/pay/edit/:id', component: PayEdit, props: true },
      { path: '/pay/list', component: PayList },

      { path: '/dataoke/edit', component: DataokeEdit },

      { path: '/wxmp/create', component: WxmpEdit },
      { path: '/wxmp/list', component: WxmpList },
      { path: '/wxmp/edit/:id', component: WxmpEdit, props: true },
      { path: '/wxmp/boardcast', component: WxmpBoardcast },

      { path: '/product/create', component: ProductEdit },
      { path: '/product/edit/:id', component: ProductEdit, props: true },
      { path: '/product/list', component: ProductList },

      { path: '/order/create', component: OrderEdit },
      { path: '/order/edit/:id', component: OrderEdit, props: true },
      { path: '/order/list', component: OrderList },

      { path: '/user/create', component: UserEdit },
      { path: '/user/edit/:id', component: UserEdit, props: true },
      { path: '/user/list', component: UserList }
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.meta.isPublic && !localStorage.token) {
    return next('/login')
  }
  next()
})

export default router
