<template>
    <div>
      <h1>{{id ? '编辑' : '新建'}}账号</h1>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="账号">
          <el-input v-model="form.nickName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    id: {}
  },
  data () {
    return {
      form: {}
    }
  },
  computed: {},
  watch: {},
  created () {
    this.id && this.fetch()
  },
  mounted () {},
  methods: {
    async fetch () {
      const res = await this.$http.get(`/rest/user/${this.id}`)
      this.form = res.data
    },

    async onSubmit () {
      let res
      if (this.id) {
        res = await this.$http.put(`/rest/user/${this.id}`, this.form)
      } else {
        res = await this.$http.post('/rest/user', this.form)
      }
      console.log(res.data)
      this.$router.push('/user/list')
    }
  }
}
</script>

<style scoped lang="less">
</style>
