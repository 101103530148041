<template>
    <div>
      <h1>{{ id ? '编辑' : '新建'}} 订单</h1>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="用户">
          <el-select v-model="form.userId">
            <el-option
              v-for="item in userList"
              :key="item._id"
              :label="item.nickName"
              :value="item._id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!--el-form-item label="Product" v-for="item in form.products" :key="item">
          <el-select v-model="products" >
            <el-option
              v-for="i in productList"
              :key="i._id"
              :label="i.name"
              :value="item._id"
            ></el-option>
          </el-select>
          数量：
          <el-input v-model="item.num" placeholder="数量" size="small" ></el-input>
        </el-form-item-->
        <el-form-item label="商品" >
          <el-select v-model="newProduct" >
            <el-option
              v-for="i in productList"
              :key="i._id"
              :label="i.name"
              :value="i._id"
            ></el-option>
          </el-select>
          数量：
          <el-input v-model="num" placeholder="数量" size="medium" style="width: 20%"></el-input>
        </el-form-item>
        <el-form-item label="日期时间">
          <el-date-picker
            v-model="form.date"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="form.status">
          <el-radio :label="0">启用</el-radio>
          <el-radio :label="1">禁用</el-radio>
          <el-radio :label="2">删除</el-radio>
        </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
</template>

<script>

export default {
  name: '',
  components: {},
  props: {
    id: {}
  },
  data () {
    return {
      userList: [],
      productList: [],
      form: {
        userId: '',
        date: '',
        status: 0,
        products: []
      },
      newProduct: '',
      num: 1
    }
  },
  computed: {},
  watch: {},
  created () {
    this.fetchUserList()
    this.fetchProductList()
    this.id && this.fetch()
  },
  mounted () {},
  methods: {
    async fetch () {
      const res = await this.$http.get(`/rest/order/${this.id}`)
      this.form = res.data
      this.newProduct = res.data.products[0].productId
      this.num = res.data.products[0].num
    },

    async fetchProductList () {
      const res = await this.$http.get('/rest/product')
      this.productList = res.data
    },

    async fetchUserList () {
      const res = await this.$http.get('/rest/user/')
      this.userList = res.data
    },

    async onSubmit () {
      let res
      if (this.id) {
        res = await this.$http.put(`/rest/order/${this.id}`, this.form)
      } else {
        const obj = {
          productId: this.newProduct,
          num: this.num
        }
        this.form.products.push(obj)
        res = await this.$http.post('/rest/order', this.form)
      }
      console.log(res.data)
      this.$router.push('/order/list')
    }
  }
}
</script>

<style scoped lang="less">
</style>
