<template>
    <div>
      <h1>群发消息</h1>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="文本">
          <el-input
            type="textarea"
            placeholder="输入内容"
            v-model="form.body"
            clearable
            autosize
            resize="both">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
</template>

<script>

export default {
  name: '',
  components: {},
  props: {
    id: {}
  },
  data () {
    return {
      form: {
        body: ''
      }
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {
    async getToken () {
      const appid = 'wx658f81bd58922037'
      const appsecret = '85313db21ab76888e1cbbbdac9674081'
      const url = `https://api.weixin.qq.com/cgi-bin/token?grant_type=client_credential&appid=${appid}&secret=${appsecret}`
      console.log(url)

      const res = await this.$http.get(url)
      console.log(res)
    },

    async onSubmit () {
      // await this.getToken()
      await this.$http.get('/wxmp/boardcast')
    }
  }
}
</script>

<style scoped lang="less">
</style>
