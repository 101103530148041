<template>
  <el-container style="height: 100vh">
    <el-aside width="200px" style="background-color: rgb(238, 241, 246)">
      <el-menu router :default-active="$route.path">
        <el-submenu index="1">
          <template slot="title">
            <i class="el-icon-user"></i>
            <span>账号</span>
          </template>
          <el-menu-item index="/admin_user/create">新建账号</el-menu-item>
          <el-menu-item index="/admin_user/list">账号列表</el-menu-item>
        </el-submenu>
        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-s-unfold"></i>
            <span>分类</span>
          </template>
          <el-menu-item index="/category/create">新建分类</el-menu-item>
          <el-menu-item index="/category/list">分类列表</el-menu-item>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">
            <i class="el-icon-s-finance"></i>
            <span>文章</span>
          </template>
          <el-menu-item index="/article/create">新建文章</el-menu-item>
          <el-menu-item index="/article/list">文章列表</el-menu-item>
        </el-submenu>
        <el-submenu index="4">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>dataoke</span>
          </template>
          <el-menu-item index="/dataoke/edit">dataoke设置</el-menu-item>
        </el-submenu>
        <el-submenu index="5">
          <template slot="title">
            <i class="el-icon-bell"></i>
            <span>公众号</span>
          </template>
          <el-menu-item index="/wxmp/create">新建关键字</el-menu-item>
          <el-menu-item index="/wxmp/list">关键字列表</el-menu-item>
          <el-menu-item index="/wxmp/boardcast">群发消息</el-menu-item>
        </el-submenu>
        <el-submenu index="6">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>pay</span>
          </template>
          <el-menu-item index="/pay/create">编辑pay</el-menu-item>
          <el-menu-item index="/pay/list">pay列表</el-menu-item>
        </el-submenu>
        <el-submenu index="7">
          <template slot="title">
            <i class="el-icon-goods"></i>
            <span>商品</span>
          </template>
          <el-menu-item index="/product/create">新建商品</el-menu-item>
          <el-menu-item index="/product/list">商品列表</el-menu-item>
        </el-submenu>
        <el-submenu index="8">
          <template slot="title">
            <i class="el-icon-s-order"></i>
            <span>订单</span>
          </template>
          <el-menu-item index="/order/create">新建订单</el-menu-item>
          <el-menu-item index="/order/list">订单列表</el-menu-item>
        </el-submenu>
        <el-submenu index="9">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>用户</span>
          </template>
          <el-menu-item index="/user/create">新建用户</el-menu-item>
          <el-menu-item index="/user/list">用户列表</el-menu-item>
        </el-submenu>
      </el-menu>
    </el-aside>

    <el-container>
      <el-header>Share-Platform</el-header>
      <el-main>
        <router-view :key="$route.path" />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: 'Main',
  components: {},
  props: {},
  data () {
    return {}
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {}
}
</script>

<style scoped lang="less">
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}
</style>
