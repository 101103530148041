<template>
    <div>
      <h1>{{id ? '编辑' : '新建'}}商品</h1>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="所属分类">
          <el-select v-model="form.category" multiple>
            <el-option
              v-for="item in category"
              :key="item._id"
              :label="item.name"
              :value="item._id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="form.desc" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="图片" v-for="fit in form.img" :key="fit" :index="index">
          <el-image
            style="width: 100px; height: 100px"
            :src="fit"
            fit="fill">
          </el-image>
        </el-form-item>
        <el-form-item label="图片" v-if="form.img">
          <el-upload
            action="http://127.0.0.1:3000/admin/api/upload"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess">
            <i class="el-icon-plus" ></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="form.status">
          <el-radio :label="0">启用</el-radio>
          <el-radio :label="1">禁用</el-radio>
          <el-radio :label="2">删除</el-radio>
        </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
</template>

<script>

export default {
  name: '',
  components: {},
  props: {
    id: {}
  },
  data () {
    return {
      form: {},
      category: [],
      dialogImageUrl: '',
      dialogVisible: false
    }
  },
  computed: {},
  watch: {},
  created () {
    this.fetchCategory()
    this.id && this.fetch()
  },
  mounted () {},
  methods: {
    async fetch () {
      const res = await this.$http.get(`/rest/product/${this.id}`)
      this.form = res.data
    },

    async fetchCategory () {
      const res = await this.$http.get('/rest/category')
      this.category = res.data
    },

    async uploadImage (file) {
      const res = await this.$http.post('upload', file)
      return res.data.url
    },

    handleRemove (file, fileList) {
      console.log(file, fileList)
    },

    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },

    handleSuccess (res, file, fileList) {
      console.log(res)
      this.form.img.push(res.url)
    },

    async onSubmit () {
      let res
      if (this.id) {
        res = await this.$http.put(`/rest/product/${this.id}`, this.form)
      } else {
        res = await this.$http.post('/rest/product', this.form)
      }
      console.log(res.data)
      this.$router.push('/product/list')
    }
  }
}
</script>

<style scoped lang="less">
</style>
