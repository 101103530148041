import { render, staticRenderFns } from "./WxmpList.vue?vue&type=template&id=2508feea&scoped=true&"
import script from "./WxmpList.vue?vue&type=script&lang=js&"
export * from "./WxmpList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2508feea",
  null
  
)

export default component.exports