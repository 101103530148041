<template>
    <div>
      <h1>{{id ? '编辑' : '新建'}}文章</h1>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="所属分类">
          <el-select v-model="form.category" multiple>
            <el-option
              v-for="item in category"
              :key="item._id"
              :label="item.name"
              :value="item._id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文章标题">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <quill-editor
              v-model="form.body"
              :options="editorOption"
              ref="quill"
              >
          </quill-editor>
        </el-form-item>
        <el-form-item label="文本">
          <el-input
            type="textarea"
            placeholder="输入内容"
            v-model="form.secret"
            clearable
            autosize
            resize="both">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
      <input type="file" @change="change" id="upload" style="display:none;" />
    </div>
</template>

<script>
import { quillEditor, Quill } from 'vue-quill-editor'
import { container, ImageExtend } from 'quill-image-extend-module'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Quill.register('modules/ImageExtend', ImageExtend)

export default {
  name: '',
  components: { quillEditor },
  props: {
    id: {}
  },
  data () {
    return {
      form: {},
      category: [],
      editorOption: {
        modules: {
          ImageExtend: {
            // 可选参数 是否显示上传进度和提示语
            loading: true,
            // 图片参数名
            name: 'img',
            // 可选参数 图片大小，单位为M，1M = 1024kb
            size: 3,
            // 服务器地址, 如果action为空，则采用base64插入图片
            action: this.uploadImage,
            // response 为一个函数用来获取服务器返回的具体图片地址
            // 例如服务器返回{code: 200; data:{ url: 'xxx.com'}}
            // 则 return res.data.url
            response: (res) => {
              console.log(res)
            },
            // 可选参数 设置请求头部
            headers: (xhr) => {},
            // 可选参数 自定义开始上传触发事件
            start: () => {},
            // 可选参数 自定义上传结束触发的事件，无论成功或者失败
            end: () => {},
            // 可选参数 自定义网络错误触发的事件
            error: () => {},
            // 可选参数 选择图片触发，也可用来设置头部，但比headers多了一个参数，可设置formData
            change: (xhr, formData) => {}
          },
          toolbar: {
            // container为工具栏，此次引入了全部工具栏，也可自行配置
            container: container,
            handlers: {
              image: function (value) {
                if (value) {
                  // 劫持原来的图片点击按钮事件
                  document.querySelector('#upload').click()
                } else {
                  this.quill.format('image', false)
                }
              }
            }
          }
        }
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.fetchCategory()
    this.id && this.fetch()
  },
  mounted () {},
  methods: {
    async fetch () {
      const res = await this.$http.get(`/rest/article/${this.id}`)
      this.form = res.data
    },

    async fetchCategory () {
      const res = await this.$http.get('/rest/category')
      this.category = res.data
    },

    async uploadImage (file) {
      const res = await this.$http.post('upload', file)
      return res.data.url
    },

    async onSubmit () {
      let res
      if (this.id) {
        res = await this.$http.put(`/rest/article/${this.id}`, this.form)
      } else {
        res = await this.$http.post('/rest/article', this.form)
      }
      console.log(res.data)
      this.$router.push('/article/list')
    },

    async change (e) {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('file', file)
      const quill = this.$refs.quill.quill
      const url = await this.uploadImage(formData)
      // 光标位置
      const length = quill.getSelection().index
      // 插入图片  图片地址
      quill.insertEmbed(length, 'image', url)
      // 调整光标到最后 //光标后移一位
      quill.setSelection(length + 1)
    }
  }
}
</script>

<style scoped lang="less">
</style>
