<template>
    <el-table
      :data="items"
      style="width: 100%">
      <el-table-column
        prop="name"
        label="标题">
      </el-table-column>
      <el-table-column
        prop="desc"
        label="描述">
      </el-table-column>
      <el-table-column
        prop="category"
        :formatter="fomartterCategory"
        label="分类">
      </el-table-column>
      <el-table-column
        prop="status"
        :formatter="fomartterStatus"
        label="状态">
      </el-table-column>
      <el-table-column
        label="图片"
        align="center">
        <template slot-scope="scope" >
        <span v-for="(item,index) in scope.row.img" :key="index">
          <img
              slot="reference"
              :src="item"
              :alt="item"
              style="max-height: 50px;max-width: 50px; padding: 5px"
          />
        </span>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="200">
        <template slot-scope="scope">
          <el-button type="primary" @click="$router.push(`/product/edit/${scope.row._id}`)" size="small">编辑</el-button>
          <el-button type="danger" @click="remove(scope.row)" size="small">删除</el-button>
        </template>
        </el-table-column>
    </el-table>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      // 列表数据
      items: [],
      // 目录分类
      category: []
    }
  },
  computed: {},
  watch: {},
  created () {
    this.fetchCategory()
    this.fetch()
  },
  mounted () {},
  methods: {
    // 获取列表
    async fetch () {
      const res = await this.$http.get('rest/product')
      this.items = res.data
    },
    // 获取目录分类列表
    async fetchCategory () {
      const res = await this.$http.get('rest/category')
      this.category = res.data
    },
    // 格式化分类名称
    fomartterCategory (row, col) {
      let ret = ''
      Array.prototype.forEach.call(this.category, item => {
        Array.prototype.forEach.call(row.category, itemId => {
          if (itemId === item._id) {
            if (ret !== '') {
              ret += '; '
            }
            ret += item.name
          }
        })
      })
      return ret
    },
    // 格式化状态
    fomartterStatus (row, col) {
      const status = row.status
      if (status === 0) {
        return '启用'
      } else if (status === 1) {
        return '禁用'
      } else if (status === 2) {
        return '删除'
      }
      return '其他状态'
    },
    // 删除数据
    remove (row) {
      this.$confirm(`确定删除 "${row.title}" ?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$http.delete(`rest/product/${row._id}`)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.fetch()
      })
    }
  }
}
</script>

<style scoped lang="less">
</style>
