<template>
    <el-table
      stripe
      :data="items"
      style="width: 100%">
      <!--el-table-column
        prop="_id"
        label="ID"
        width="220">
      </el-table-column-->
      <el-table-column
        prop="type"
        label="类型">
      </el-table-column>
      <el-table-column
        prop="keywords"
        label="关键字">
      </el-table-column>
      <el-table-column
        prop="replyType"
        label="回复类型">
      </el-table-column>
      <el-table-column
        prop="replyBody"
        label="文本">
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="200">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.enable" @change="switchEnable(scope.row)"></el-switch>
          <el-button type="primary" @click="$router.push(`/wxmp/edit/${scope.row._id}`)" size="small">编辑</el-button>
          <el-button type="danger" @click="remove(scope.row)" size="small">删除</el-button>
        </template>
        </el-table-column>
    </el-table>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      items: []
    }
  },
  computed: {},
  watch: {},
  created () {
    this.fetch()
  },
  mounted () {},
  methods: {
    async fetch () {
      const res = await this.$http.get('rest/wxmp')
      this.items = res.data
    },
    async switchEnable (row) {
      await this.$http.put(`/rest/wxmp/${row._id}`, row)
      this.fetch()
    },
    remove (row) {
      this.$confirm(`确定删除 "${row.keywords}" ?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$http.delete(`rest/wxmp/${row._id}`)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.fetch()
      })
    }
  }
}
</script>

<style scoped lang="less">
</style>
