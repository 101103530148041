<template>
    <div>
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="标题">
          <el-input v-model="form.subject"></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input v-model="form.body"></el-input>
        </el-form-item>
        <el-form-item label="自定义订单号">
          <el-input v-model="form.out_trade_no"></el-input>
        </el-form-item>
        <el-form-item label="超时时间">
          <el-input v-model="form.timeout_express"></el-input>
        </el-form-item>
        <el-form-item label="总金额">
          <el-input v-model="form.total_amount"></el-input>
        </el-form-item>
        <el-form-item label="不可抵扣金额">
          <el-input v-model="form.undiscountable_amount"></el-input>
        </el-form-item>
        <el-form-item label="商品详情">
          <el-input v-model="form.goods_detail"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>

      <qrcode :value="qrcode_url" :options="{ size: 200 }"/>

      <!--查询结果-->
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>交易结果</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="getResult">查询</el-button>
        </div>
        <el-form ref="form" :model="result" label-width="100px">
          <el-form-item label="买家登录账号id">
            <el-input v-model="result.buyer_logon_id"></el-input>
          </el-form-item>
          <el-form-item label="买家付款金额">
            <el-input v-model="result.buyer_pay_amount"></el-input>
          </el-form-item>
          <el-form-item label="买家账号id">
            <el-input v-model="result.buyer_user_id"></el-input>
          </el-form-item>
          <el-form-item label="code">
            <el-input v-model="result.code"></el-input>
          </el-form-item>
          <el-form-item label="msg">
            <el-input v-model="result.msg"></el-input>
          </el-form-item>
          <el-form-item label="invoice_amount">
            <el-input v-model="result.invoice_amount"></el-input>
          </el-form-item>
          <el-form-item label="自定义交易编号">
            <el-input v-model="result.out_trade_no"></el-input>
          </el-form-item>
          <el-form-item label="point_amount">
            <el-input v-model="result.point_amount"></el-input>
          </el-form-item>
          <el-form-item label="receipt_amount">
            <el-input v-model="result.receipt_amount"></el-input>
          </el-form-item>
          <el-form-item label="总金额">
            <el-input v-model="result.total_amount"></el-input>
          </el-form-item>
          <el-form-item label="交易号">
            <el-input v-model="result.trade_no"></el-input>
          </el-form-item>
          <el-form-item label="交易状态">
            <el-input v-model="result.trade_status"></el-input>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
</template>

<script>
import axios from 'axios'
import Qrcode from '@xkeshi/vue-qrcode'

const httpWeb = axios.create({
  baseURL: 'http://127.0.0.1:3000/web/api'
})

export default {
  name: '',
  components: {
    qrcode: Qrcode
  },
  props: {
    id: {}
  },
  data () {
    return {
      qrcode_url: '',
      form: {
        body: '内容正文',
        goods_detail: '商品详情',
        out_trade_no: '050502101',
        subject: '标题',
        timeout_express: '5m',
        total_amount: '0.04',
        undiscountable_amount: '0.01'
      },
      result: {
        buyer_logon_id: '',
        buyer_pay_amount: '',
        buyer_user_id: '',
        code: '',
        invoice_amount: '',
        msg: '',
        out_trade_no: '',
        point_amount: '',
        receipt_amount: '',
        total_amount: '',
        trade_no: '',
        trade_status: ''
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.id && this.fetch()
  },
  mounted () {},
  methods: {
    async fetch () {
      const res = await this.$http.get(`/rest/pay/${this.id}`)
      this.form = res.data

      this.getResult()
    },

    async getResult () {
      const outTradeId = this.form.out_trade_no
      if (typeof outTradeId !== 'string' || !outTradeId) {
        return
      }
      const res = await httpWeb.get(`/f2fpay/query?id=${outTradeId}`)
      console.log(res.data)
      this.result = res.data.alipay_trade_query_response
    },

    async onSubmit () {
      let res
      if (this.id) {
        res = await this.$http.put(`/rest/pay/${this.id}`, this.form)
      } else {
        // res = await this.$http.post('/rest/pay', this.form)
        const re = await httpWeb.get('/f2fpay/')
        console.log(re.data)
        this.qrcode_url = re.data
      }
      console.log(res.data)
      // this.$router.push('/pay/list')
    }
  }
}
</script>

<style scoped lang="less">
</style>
