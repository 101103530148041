<template>
    <div>
      <h1>{{id ? '编辑' : '新建'}}关键字</h1>
      <el-form ref="form" :model="form" label-width="80px">
        <!--el-form-item label="type">
          <el-select v-model="form.type" multiple>
            <el-option
              v-for="item in wxmp"
              :key="item._id"
              :label="item.keywords"
              :value="item._id"
            ></el-option>
          </el-select>
        </el-form-item-->
        <el-form-item label="关键字">
          <el-input v-model="form.keywords"></el-input>
        </el-form-item>
        <el-form-item label="文本">
          <el-input
            type="textarea"
            placeholder="输入内容"
            v-model="form.replyBody"
            clearable
            autosize
            resize="both">
          </el-input>
        </el-form-item>
        <el-form-item label="启用">
          <el-switch v-model="form.enable" ></el-switch>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
      <input type="file" @change="change" id="upload" style="display:none;" />
    </div>
</template>

<script>

export default {
  name: '',
  components: {},
  props: {
    id: {}
  },
  data () {
    return {
      form: {
        type: 'text',
        replyType: 'text'
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.id && this.fetch()
  },
  mounted () {},
  methods: {
    async fetch () {
      const res = await this.$http.get(`/rest/wxmp/${this.id}`)
      this.form = res.data
    },

    async uploadImage (file) {
      const res = await this.$http.post('upload', file)
      return res.data.url
    },

    async onSubmit () {
      if (this.id) {
        await this.$http.put(`/rest/wxmp/${this.id}`, this.form)
      } else {
        await this.$http.post('/rest/wxmp', this.form)
      }

      this.$router.push('/wxmp/list')
    },

    async change (e) {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('file', file)
      const quill = this.$refs.quill.quill
      const url = await this.uploadImage(formData)
      // 光标位置
      const length = quill.getSelection().index
      // 插入图片  图片地址
      quill.insertEmbed(length, 'image', url)
      // 调整光标到最后 //光标后移一位
      quill.setSelection(length + 1)
    }
  }
}
</script>

<style scoped lang="less">
</style>
