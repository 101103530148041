<template>
    <el-table
      :data="items"
      style="width: 100%">
      <!--el-table-column
        prop="_id"
        label="ID">
      </el-table-column-->
      <el-table-column
        prop="date"
        label="日期时间">
      </el-table-column>
      <el-table-column
        prop="userId"
        :formatter="fomartterUser"
        label="用户">
      </el-table-column>
      <el-table-column
        prop="products"
        :formatter="fomartterProduct"
        label="商品">
      </el-table-column>
      <el-table-column
        prop="status"
        :formatter="fomartterStatus"
        label="状态">
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="200">
        <template slot-scope="scope">
          <el-button type="primary" @click="$router.push(`/order/edit/${scope.row._id}`)" size="small">编辑</el-button>
          <el-button type="danger" @click="remove(scope.row)" size="small">删除</el-button>
        </template>
        </el-table-column>
    </el-table>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      // 列表数据
      items: [],
      userList: [],
      productList: []
    }
  },
  computed: {},
  watch: {},
  created () {
    this.fetchUserList()
    this.fetchProductList()
    this.fetch()
  },
  mounted () {},
  methods: {
    // 获取列表
    async fetch () {
      const res = await this.$http.get('rest/order')
      this.items = res.data
    },

    async fetchUserList () {
      const res = await this.$http.get('/rest/user/')
      this.userList = res.data
    },

    async fetchProductList () {
      const res = await this.$http.get('/rest/product')
      this.productList = res.data
    },

    fomartterProduct (row, col) {
      let ret = ''
      Array.prototype.forEach.call(this.productList, item => {
        Array.prototype.forEach.call(row.products, itemProduct => {
          if (itemProduct.productId === item._id) {
            if (ret !== '') {
              ret += '; '
            }
            ret += item.name
            ret += ' (' + itemProduct.num + ')'
          }
        })
      })
      return ret
    },

    fomartterUser (row, col) {
      let ret = ''
      Array.prototype.forEach.call(this.userList, item => {
        if (row.userId === item._id) {
          if (ret !== '') {
            ret += '; '
          }
          ret += item.nickName
        }
      })
      return ret
    },

    // 格式化状态
    fomartterStatus (row, col) {
      const status = row.status
      if (status === 0) {
        return '启用'
      } else if (status === 1) {
        return '禁用'
      } else if (status === 2) {
        return '删除'
      }
      return '其他状态'
    },
    // 删除数据
    remove (row) {
      this.$confirm(`确定删除 "${row._id}" ?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$http.delete(`rest/order/${row._id}`)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.fetch()
      })
    }
  }
}
</script>

<style scoped lang="less">
</style>
