<template>
  <div>
    <h1>设置app_key & app_secret</h1>
    <el-form ref="form" :model="formdata" label-width="80px">
      <el-form-item label="app_key">
        <el-input v-model="formdata.app_key"></el-input>
      </el-form-item>
      <el-form-item label="app_secret">
        <el-input v-model="formdata.app_secret"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      formdata: {
        name: 'dataoke',
        app_key: '',
        app_secret: ''
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.fetch()
  },
  mounted () {},
  methods: {
    async fetch () {
      const res = await this.$http.get('/setting/dataoke')
      this.formdata.app_key = res.data.key
      this.formdata.app_secret = res.data.value
    },

    async onSubmit () {
      if (this.formdata.app_key && this.formdata.app_secret) {
        await this.$http.put('/setting/dataoke', this.formdata)
        await this.fetch()
      }
    }
  }
}
</script>

<style scoped lang="less">
</style>
